var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-100 bg-mainbg" },
    [
      _c("date-picker", {
        ref: "datePicker",
        staticClass: "mr-3",
        attrs: {
          range: "",
          type: "date",
          clearable: false,
          "range-separator": " - ",
          format: "DD/MM/YYYY",
          "disabled-date": (date) => date >= new Date(),
        },
        on: { change: _vm.changeDate },
        model: {
          value: _vm.dateRange,
          callback: function ($$v) {
            _vm.dateRange = $$v
          },
          expression: "dateRange",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }