var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { attrs: { id: "breadcrumb" } },
      [
        _c(
          "v-toolbar",
          {
            staticClass: "toolbarHeader",
            attrs: { absolute: "", color: "white", short: "" },
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-space-between w-100 ml-4 align-center",
                staticStyle: { height: "100%" },
              },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      height: "100%",
                      display: "flex",
                      "align-items": "center",
                    },
                  },
                  [
                    _c("v-toolbar-title", { staticClass: "titleToolbar" }, [
                      _vm._v(" " + _vm._s(_vm.title) + " "),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  [_vm.datePickerActive ? _c("dateSelector") : _vm._e()],
                  1
                ),
              ]
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }